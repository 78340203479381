<template>
  <v-form>
    <v-container fluid>
      <v-card elevation="12" class="pa-10">
        <v-row>
          <v-col>
            <h2>Logos</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="justify-center align-self-center">
            <v-subheader class='text-center align-center'>Logo FRAC</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="2">
            <v-img
              :lazy-src="links.logo_frac"
              max-height="80"
              max-width="80"
              class="mr-5"
              :src="links.logo_frac"
            ></v-img>
            <!-- <v-text-field
              v-model="links.arquivo_frac"
              :rules="fieldRequired"
              disabled
              label="Logo FRAC"
              dense
              outlined
              required
            ></v-text-field> -->
            <v-btn
              :loading="loadingBtnFrac"
              :disabled="loadingBtnFrac"
              color="blue-grey"
              class="white--text justify-center align-self-center"
              @click="clickUploadFrac"
            >
              Upload
              <v-icon
                right
                dark
              >
                mdi-camera-outline
              </v-icon>
            </v-btn>
            <input
              ref="uploaderFRAC"
              class="d-none"
              type="file"
              accept="image/*"
              @change="onFileChanged"
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="justify-center align-self-center">
            <v-subheader>Logo HRAC</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="1">
            <v-img
              :lazy-src="links.logo_hrac"
              max-height="80"
              max-width="80"
              class="mr-5"
              :src="links.logo_hrac"
            ></v-img>
            <!-- <v-text-field
              v-model="links.arquivo_hrac"
              :rules="fieldRequired"
              disabled
              label="Logo HRAC"
              dense
              outlined
              required
            ></v-text-field> -->
            <v-btn
              :loading="loadingBtnHrac"
              :disabled="loadingBtnHrac"
              color="blue-grey"
              class="white--text justify-center align-self-center"
              @click="clickUploadHrac"
            >
              Upload
              <v-icon
                right
                dark
              >
                mdi-camera-outline
              </v-icon>
            </v-btn>
            <input
              ref="uploaderHRAC"
              class="d-none"
              type="file"
              accept="image/*"
              @change="onFileChanged"
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="justify-center align-self-center">
            <v-subheader>Logo IRAC</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="1">
            <v-img
              :lazy-src="links.logo_irac"
              max-height="80"
              max-width="80"
              class="mr-5"
              :src="links.logo_irac"
            ></v-img>
            <!-- <v-text-field
              v-model="links.arquivo_irac"
              :rules="fieldRequired"
              label="Logo IRAC"
              disabled
              dense
              outlined
              required
            ></v-text-field> -->
            <v-btn
              :loading="loadingBtnIrac"
              :disabled="loadingBtnIrac"
              color="blue-grey"
              class="white--text justify-center align-self-center"
              @click="clickUploadIrac"
            >
              Upload
              <v-icon
                right
                dark
              >
                mdi-camera-outline
              </v-icon>
            </v-btn>
            <input
              ref="uploaderIRAC"
              class="d-none"
              type="file"
              accept="image/*"
              @change="onFileChanged"
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2" class="justify-center align-self-center">
            <v-subheader>Logo GNEMA</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="3">
            <v-img
              :lazy-src="links.logo_gnema"
              max-height="80"
              max-width="80"
              class="mr-5"
              :src="links.logo_gnema"
            ></v-img>
            <!-- <v-text-field
              v-model="links.arquivo_gnema"
              :rules="fieldRequired"
              label="Logo GNEMA"
              disabled
              dense
              outlined
              required
            ></v-text-field> -->
            <v-btn
              :loading="loadingBtnGnema"
              :disabled="loadingBtnGnema"
              color="blue-grey"
              class="white--text justify-center align-self-center"
              @click="clickUploadGnema"
            >
              Upload
              <v-icon
                right
                dark
              >
                mdi-camera-outline
              </v-icon>
            </v-btn>
            <input
              ref="uploaderGNEMA"
              class="d-none"
              type="file"
              accept="image/*"
              @change="onFileChanged"
            >
          </v-col>
        </v-row>
         <v-row>
          <v-col>
            <h2>Links Sites</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Link Site FRAC</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="6">
            <v-text-field
              v-model="links.site_frac"
              :rules="fieldRequired"
              label="Link Site FRAC"
              dense
              outlined
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Link Site HRAC</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="6">
            <v-text-field
              v-model="links.site_hrac"
              :rules="fieldRequired"
              label="Link Site HRAC"
              dense
              outlined
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Link Site IRAC</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="6">
            <v-text-field
              v-model="links.site_irac"
              :rules="fieldRequired"
              label="Link Site IRAC"
              dense
              outlined
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Link Site GNEMA</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="6">
            <v-text-field
              v-model="links.site_gnema"
              :rules="fieldRequired"
              label="Link Site GNEMA"
              dense
              outlined
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn dark style="margin-left: 5px; margin-top: 2px" color="green" @click="salvar">
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
    >
      {{ aviso }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
import { BASE_URL } from '../util/constants';
export default {
  data: () => ({
    url: "https://apiadmin.allgeconsultoria.com/index.php/links/",
    overlay: false,
    snackbar: false,
    loadingBtnFrac: false,
    loadingBtnHrac: false,
    loadingBtnIrac: false,
    loadingBtnGnema: false,
    comiteUpload: null,
    arquivoUpload: null,
    aviso: '',
    links: {
      id: "",
      site_frac: "",
      site_hrac: '',
      site_irac: '',
      site_gnema: '',
      logo_frac: '',
      logo_hrac: '',
      logo_irac: '',
      logo_gnema: '',
      arquivo_frac: '',
      arquivo_hrac: '',
      arquivo_irac: '',
      arquivo_gnema: '',
    },
    fieldRequired: [(v) => !!v || "Campo Obrigatorio"],
  }),
  created() {
    this.url = `${BASE_URL}links/`;
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.fetchLinks();
    },
    async fetchLinks() {
      try {
        let response = await fetch(`${this.url}`);
        response = await response.json();
        this.links = response;
      } catch (error) {
        this.links = {
          id: "",
          site_frac: "",
          site_hrac: '',
          site_irac: '',
          site_gnema: '',
          logo_frac: '',
          logo_hrac: '',
          logo_irac: '',
          logo_gnema: '',
          arquivo_frac: '',
          arquivo_hrac: '',
          arquivo_irac: '',
          arquivo_gnema: '',
        };
      }
    },
    async salvar() {
      this.overlay = true;
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      let urlencoded = new URLSearchParams();
      urlencoded.append("site_frac", this.links.site_frac);
      urlencoded.append("site_hrac", this.links.site_hrac);
      urlencoded.append("site_irac", this.links.site_irac);
      urlencoded.append("site_gnema", this.links.site_gnema);
      urlencoded.append("arquivo_frac", this.links.arquivo_frac);
      urlencoded.append("arquivo_hrac", this.links.arquivo_hrac);
      urlencoded.append("arquivo_irac", this.links.arquivo_irac);
      
      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
      };

      fetch(`${this.url}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          this.overlay = false;
          this.aviso = 'Salvo com Sucesso';
          this.snackbar = true;
        })
        .catch(error => {
          console.log(error)
          this.overlay = false;
          this.aviso = 'Problema ao Salvar, entre em contato com o suporte';
          this.snackbar = true;
        });
    },
    clickUploadFrac() {
      this.$refs.uploaderFRAC.click();
      this.comiteUpload = 'frac';
    },
    clickUploadHrac() {
      this.$refs.uploaderHRAC.click();
      this.comiteUpload = 'hrac';
    },
    clickUploadIrac() {
      this.$refs.uploaderIRAC.click();
      this.comiteUpload = 'irac';
    },
    clickUploadGnema() {
      this.$refs.uploaderGNEMA.click();
      this.comiteUpload = 'gnema';
    },
    onFileChanged(e) {
      console.log(this.comiteUpload);
      this.arquivoUpload = e.target.files[0];

      if (this.comiteUpload === 'frac') {
        this.loadingBtnFrac = true;  
      } else if (this.comiteUpload === 'hrac') {
        this.loadingBtnHrac = true;
      } else if (this.comiteUpload === 'irac') {
        this.loadingBtnIrac = true;
      } else if (this.comiteUpload === 'gnema') {
        this.loadingBtnGnema = true;
      }

      this.uploadArquivo();
    },
    async uploadArquivo() {
      // this.overlay = true;
      const formData = new FormData();
      formData.append('file', this.arquivoUpload);
      formData.append('comite', this.comiteUpload);
      // const url = 'http://comites.allgeconsultoria.com/upload.php';
      console.log(this.arquivoUpload);
      console.log(this.comiteUpload);
      try {
        let response = await fetch(`${this.url}upload-logo`, {
          method: 'POST',
          body: formData,
        });
        response = await response.json();
        console.log(response);
        if (response.status) {
          this.aviso = 'Logo atualizada com sucesso';
          if (this.comiteUpload === 'frac') {
            this.links.logo_frac = response.base64;
          } else if (this.comiteUpload === 'hrac') {
            this.links.logo_hrac = response.base64;
          } else if (this.comiteUpload === 'irac') {
            this.links.logo_irac = response.base64;
          } else if (this.comiteUpload === 'gnema') {
            this.links.logo_gnema = response.base64;
          }
        } else {
          this.aviso = response.msg;
        }
      } catch (error) {
        this.aviso = 'Problema ao Salvar, entre em contato com o suporte';
      } finally {
        this.overlay = false;
        this.loadingBtnFrac = false;
        this.loadingBtnHrac = false;
        this.loadingBtnIrac = false;
        this.loadingBtnGnema = false;
        this.snackbar = true;
      }
      // fetch(this.url, {
      //   method: 'POST',
      //   body: formData,
      // })
      // .then(response => response.json())
      // .then(data => {
      //   console.log(data);
      //   this.overlay = false;
      //   this.loadingBtnFrac = false;
      //   this.loadingBtnHrac = false;
      //   this.loadingBtnIrac = false;
      //   this.loadingBtnGnema = false;
      //   this.aviso = 'Arquivo PDF atualizado com sucesso';
      //   this.snackbar = true;
      // })
      // .catch(error => {
      //   console.log(error);
      //   this.overlay = false;
      //   this.loadingBtnFrac = false;
      //   this.loadingBtnHrac = false;
      //   this.loadingBtnIrac = false;
      //   this.loadingBtnGnema = false;
      //   this.aviso = 'Problema ao Salvar, entre em contato com o suporte';
      //   this.snackbar = true;
      // })

    }
  },
};
</script>
