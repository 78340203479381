<template>
  <v-form>
    <v-container fluid>
      <v-card elevation="12" class="pa-10">
        <v-row>
          <v-col>
            <h2>Sítio de Ação Primário - GNEMA</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Grupo</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="3">
            <v-text-field
              v-model="sitio.grupo"
              :rules="fieldRequired"
              label="Grupo"
              dense
              outlined
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Sítio de Ação Primário</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="3">
            <v-text-field
              v-model="sitio.sitio"
              :rules="fieldRequired"
              label="Sitio"
              dense
              outlined
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn dark style="margin-left: 5px; margin-top: 2px" color="green" @click="salvar">
              Salvar
            </v-btn>
            <v-btn
              style="margin-left: 5px; margin-top: 2px"
              color="gray"
              to="/gnema/sitio"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
    >
      {{ aviso }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
import { BASE_URL } from '../../util/constants';
export default {
  data: () => ({
    url: "https://apiadmin.allgeconsultoria.com/index.php/gnema/",
    overlay: false,
    snackbar: false,
    aviso: '',
    sitio: {
      id: "",
      sitio: '',
      grupo: '',
    },
    fieldRequired: [(v) => !!v || "Campo Obrigatorio"],
  }),
  created() {
    this.url = `${BASE_URL}gnema/`;
    this.initialize();
  },
  methods: {
    async initialize() {
      if (this.$route.params.id !== "") {
        await this.fetchSitio(this.$route.params.id);
      }
    },
    async fetchSitio(id) {
      if (id == undefined) return;
      try {
        let response = await fetch(`${this.url}sitio/${id}`);
        response = await response.json();
        this.sitio = response;
      } catch (error) {
        this.sitio = {
          id: "",
          sitio: '',
          grupo: '',
        };
      }
    },
    async salvar() {
      this.overlay = true;
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      let urlencoded = new URLSearchParams();
      urlencoded.append("sitio", this.sitio.sitio);
      urlencoded.append("grupo", this.sitio.grupo);
      let method = (this.sitio.id === '' ? 'POST' : 'PUT')
      
      let requestOptions = {
        method: method,
        headers: myHeaders,
        body: urlencoded,
      };

      fetch(`${this.url}sitio/${this.sitio.id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          this.overlay = false;
          this.aviso = 'Salvo com Sucesso';
          this.snackbar = true;
          setTimeout(() => this.$router.push(`/gnema/sitio/`), 1000)
        })
        .catch(error => {
          console.log(error)
          this.overlay = false;
          this.aviso = 'Problema ao Salvar, entre em contato com o suporte';
          this.snackbar = true;
        });
    }
  },
};
</script>
