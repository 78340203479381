<template>
  <v-form>
    <v-container fluid>
      <v-card elevation="12" class="pa-10">
        <v-row>
          <v-col>
            <h2>Nome do Grupo - FRAC</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Sítio Ativo</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="3">
            <v-select
              :items="sitios"
              v-model="nome_grupo.id_sitio"
              :item-text="(item) => `${item.codigo_frac} - ${item.sitio}`"
              item-value="id"
              label="Sítio Ativo"
              dense
              outlined
              no-data-text="Adicione um Sítio Ativo para selecionar"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Nome do Grupo</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="3">
            <v-text-field
              v-model="nome_grupo.nome_grupo"
              :rules="fieldRequired"
              label="Nome do Grupo"
              dense
              outlined
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn dark style="margin-left: 5px; margin-top: 2px" color="green" @click="salvar">
              Salvar
            </v-btn>
            <v-btn
              style="margin-left: 5px; margin-top: 2px"
              color="gray"
              to="/frac/nome-grupo"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
    >
      {{ aviso }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
import { BASE_URL } from '../../util/constants';
export default {
  data: () => ({
    url: "https://apiadmin.allgeconsultoria.com/index.php/frac/",
    overlay: false,
    snackbar: false,
    aviso: '',
    sitios: [],
    nome_grupo: {
      id: "",
      id_sitio: "",
      nome_grupo: '',
    },
    fieldRequired: [(v) => !!v || "Campo Obrigatorio"],
  }),
  created() {
    this.url = `${BASE_URL}frac/`;
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.fetchSitios();
      
      if (this.$route.params.id !== "") {
        await this.fecthNomeGrupo(this.$route.params.id);
      }
    },
    async fetchSitios() {
      try {
        let response = await fetch(`${this.url}sitio`);
        response = await response.json();
        this.sitios = response.map(item => ({id: item.id, sitio: item.sitio, subgrupo: item.subgrupo, codigo_frac: item.codigo_frac})).filter(sitio => sitio.sitio !== "").sort((a, b) => a.sitio > b.sitio ? 1 : -1);
      } catch (error) {
        this.sitios = [];
      }
    },
    async fecthNomeGrupo(id) {
      try {
        let response = await fetch(`${this.url}nome-grupo/${id}`);
        response = await response.json();
        this.nome_grupo = response;
      } catch (error) {
        this.nome_grupo = {
          id: "",
          id_sitio: "",
          nome_grupo: '',
        };
      }
    },
    async salvar() {
      this.overlay = true;
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      let urlencoded = new URLSearchParams();
      urlencoded.append("nome_grupo", this.nome_grupo.nome_grupo);
      urlencoded.append("id_sitio", this.nome_grupo.id_sitio);
      let method = (this.nome_grupo.id === '' ? 'POST' : 'PUT')
      
      let requestOptions = {
        method: method,
        headers: myHeaders,
        body: urlencoded,
      };

      fetch(`${this.url}nome-grupo/${this.nome_grupo.id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          this.overlay = false;
          this.aviso = 'Salvo com Sucesso';
          this.snackbar = true;
          setTimeout(() => this.$router.push(`/frac/nome-grupo/`), 1000)
        })
        .catch(error => {
          console.log(error)
          this.overlay = false;
          this.aviso = 'Problema ao Salvar, entre em contato com o suporte';
          this.snackbar = true;
        });
    }
  },
};
</script>
