<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="#00695C" dark flat>
                <v-toolbar-title>Administrativo</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-alert type="error" v-if="showError">
                    Email ou senha incorretos
                  </v-alert>
                  <v-text-field
                    label="Email"
                    name="email"
                    v-model="email"
                    prepend-icon="mdi-account"
                    type="text"
                  ></v-text-field>

                  <v-text-field
                    id="senha"
                    label="Senha"
                    name="senha"
                    v-model="password"
                    prepend-icon="mdi-lock"
                    type="password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="#00695C"
                  dark
                  :loading="loading"
                  :disabled="loading"
                  @click="logar"
                  >Entrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  props: {
    source: String
  },
  data() {
    return {
      url: "https://apiadmin.allgeconsultoria.com/index.php/login/",
      loading: false,
      showError: false,
      email: "",
      password: ""
    };
  },
  created() {
    document.title = "Administrativo";
  },
  methods: {
    async logar() {
      this.loading = true;
      this.showError = false;
      let form = {
        email: this.email,
        password: this.password,
      };
      let formBody = [];
      for (let property in form) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(form[property]);
          formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      try {
        let res = await fetch(this.url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: formBody
        })
        if (!res.ok) {
          throw new Error('Usuario ou Senha invalidos');
        }
        localStorage.setItem("user-logado", 'true');
        this.$router.push("/frac/grupo");
      } catch (error) {
        this.showError = true;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>