<template>
  <v-form>
    <v-container fluid>
      <v-card elevation="12" class="pa-10">
        <v-row>
          <v-col>
            <h2>Grupo Químico - HRAC</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Mecanismo de Ação</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="3">
            <v-select
              :items="modo_acoes"
              v-model="familia.id_modo_acao"
              item-text="modo_acao"
              item-value="id"
              label="Mecanismo de Ação"
              dense
              outlined
              no-data-text="Adicione um Mecanismo de Ação para selecionar"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Grupo Químico</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="3">
            <v-text-field
              v-model="familia.familia_quimica"
              :rules="fieldRequired"
              label="Grupo Químico"
              dense
              outlined
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn dark style="margin-left: 5px; margin-top: 2px" color="green" @click="salvar">
              Salvar
            </v-btn>
            <v-btn
              style="margin-left: 5px; margin-top: 2px"
              color="gray"
              to="/hrac/familia"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
    >
      {{ aviso }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
import { BASE_URL } from '../../util/constants';
export default {
  data: () => ({
    url: "https://apiadmin.allgeconsultoria.com/index.php/hrac/",
    overlay: false,
    snackbar: false,
    aviso: '',
    modo_acoes: [],
    familia: {
      id: "",
      id_modo_acao: "",
      familia_quimica: '',
    },
    fieldRequired: [(v) => !!v || "Campo Obrigatorio"],
  }),
  created() {
    this.url = `${BASE_URL}hrac/`;
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.fetchModoAcoes();

      if (this.$route.params.id !== "") {
        await this.fetchFamilia(this.$route.params.id);
      }
    },
    async fetchFamilia(id) {
      try {
        let response = await fetch(`${this.url}familia/${id}`);
        response = await response.json();
        this.familia = response;
      } catch (error) {
        this.familia = {
          id: "",
          familia_quimica: '',
          id_modo_acao: ""
        };
      }
    },
    async fetchModoAcoes() {
      try {
        let response = await fetch(`${this.url}modo-acao`);
        response = await response.json();
        this.modo_acoes = response.map(item => ({id: item.id, grupo_wssa: item.grupo_wssa, grupo_hrac: item.grupo_hrac, modo_acao: item.modo_acao})).filter(modo => modo.modo_acao !== "").sort((a, b) => a.modo_acao > b.modo_acao ? 1 : -1);
      } catch (error) {
        this.modo_acoes = [];
      }
    },
    async salvar() {
      this.overlay = true;
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      let urlencoded = new URLSearchParams();
      urlencoded.append("familia_quimica", this.familia.familia_quimica);
      urlencoded.append("id_modo_acao", this.familia.id_modo_acao);
      let method = (this.familia.id === '' ? 'POST' : 'PUT')
      
      let requestOptions = {
        method: method,
        headers: myHeaders,
        body: urlencoded,
      };

      fetch(`${this.url}familia/${this.familia.id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          this.overlay = false;
          this.aviso = 'Salvo com Sucesso';
          this.snackbar = true;
          setTimeout(() => this.$router.push(`/hrac/familia/`), 1000)
        })
        .catch(error => {
          console.log(error)
          this.overlay = false;
          this.aviso = 'Problema ao Salvar, entre em contato com o suporte';
          this.snackbar = true;
        });
    }
  },
};
</script>
