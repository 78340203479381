<template>
  <v-container fluid>
  <v-data-table
    :headers="headers"
    :items="grupo_quimicos"
    :search="search"
    sort-by="id"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Grupo Químico - FRAC</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Procurar"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
            color="green"
            dark
            class="mb-2"
            to="/frac/grupo-quimico/form"
        >
            Novo Grupo Químico
        </v-btn>
    
        <v-dialog v-model="dialogDelete" max-width="320px">
          <v-card>
            <v-card-title class="headline red lighten-3 mb-6">Atenção</v-card-title>
            <v-card-text>Tem certeza que deseja deletar este item?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
    >
      {{ aviso }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { BASE_URL } from '../../util/constants';
  export default {
    data: () => ({
      url: "https://apiadmin.allgeconsultoria.com/index.php/frac/",
      overlay: false,
      snackbar: false,
      aviso: '',
      dialog: false,
      dialogDelete: false,
      search: '',
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Nome do Grupo', value: 'nome_grupo' },
        { text: 'Grupo Químico', value: 'grupo_quimico' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      grupo_quimicos: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.url = `${BASE_URL}frac/`;
      this.initialize()
    },

    methods: {
      async initialize () {
        await this.fetchGrupoQuimicos(); 
      },

      async fetchGrupoQuimicos() {
        try {
          let response = await fetch(`${this.url}grupo-quimico`);
          response = await response.json();
          this.grupo_quimicos = response;
          //this.ingredientes = response.map(item => ({id: item.id, grupo_quimico: item.grupo_quimico})).filter(item => item.grupo_quimico !== "").sort((a, b) => a.grupo_quimico > b.grupo_quimico ? 1 : -1);
        } catch (error) {
          this.grupo_quimicos = [];
        }
      },

      editItem (item) {
        console.log(item);
        this.$router.push(`/frac/grupo-quimico/edit/${item.id}`);
      },

      deleteItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.dialogDelete = false;
        this.overlay = true;
        fetch(`${this.url}grupo-quimico/${this.editedItem.id}`, {method: 'DELETE'})
          .then(response => response.json())
          .then(result => {
            console.log(result)
            this.overlay = false;
            this.aviso = 'Deletado com Sucesso';
            this.snackbar = true;
            this.grupo_quimicos = [];
            this.editedItem = {}
            this.fetchGrupoQuimicos();
          })
          .catch(error => {
            console.log(error)
            this.overlay = false;
            this.editedItem = {}
            this.aviso = 'Problema ao Deletar, entre em contato com o suporte';
            this.snackbar = true;
          });
      },


      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = {}
        })
      },
    },
  }
</script>