<template>
  <v-form>
    <v-container fluid>
      <v-card elevation="12" class="pa-10">
        <v-row>
          <v-col>
            <h2>Ingrediente Ativo - FRAC</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Grupo</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="3">
            <v-select
              :items="grupos"
              v-model="ingrediente.id_grupo"
              @change="getSitioAtivo"
              item-text="grupo"
              item-value="id"
              label="Grupo"
              dense
              outlined
              no-data-text="Adicione um Grupo para selecionar"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Sítio Ativo</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="3">
            <v-select
              :items="sitios"
              v-model="ingrediente.id_sitio"
              @change="getNomeGrupo"
              :item-text="(item) => `${item.codigo_frac} - ${item.sitio}`"
              item-value="id"
              label="Sítio Ativo"
              dense
              outlined
              no-data-text="Selecione um Grupo para continuar"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Nome do Grupo</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="3">
            <v-select
              :items="nome_grupos"
              v-model="ingrediente.id_nome_grupo"
              @change="getGrupoQuimico"
              item-text="nome_grupo"
              item-value="id"
              label="Nome do Grupo"
              dense
              outlined
              no-data-text="Selecione um Sítio Ativo para continuar"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Grupo Químico</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="3">
            <v-select
              :items="grupo_quimicos"
              v-model="ingrediente.id_grupo_quimico"
              item-text="grupo_quimico"
              item-value="id"
              label="Grupo Químico"
              dense
              outlined
              no-data-text="Selecione um Nome do Grupo para continuar"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Ingrediente Ativo</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="3">
            <v-text-field
              v-model="ingrediente.ingrediente_ativo"
              :rules="fieldRequired"
              label="Ingrediente Ativo"
              dense
              outlined
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Imagem (URL)</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="3">
            <v-text-field
              label="Imagem"
              v-model="imagem"
              dense
              outlined
              required
            ></v-text-field>
            <v-img
              :lazy-src="ingrediente.imagem"
              max-height="200"
              max-width="140"
              :src="ingrediente.imagem"
            ></v-img>
            <v-btn
              @click="removerImagem"
              elevation="2"
              class="ml-2 mr-2"
              rounded
              icon
              color="error"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn dark style="margin-left: 5px; margin-top: 2px" color="green" @click="salvar">
              Salvar
            </v-btn>
            <v-btn
              style="margin-left: 5px; margin-top: 2px"
              color="gray"
              to="/frac/ingrediente"
            >
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-dialog v-model="dialogGrupo" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Adicionar Grupo</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="grupo.grupo"
                  label="Grupo"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray darken-1" outlined @click="closeGrupo">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" dark @click="saveGrupo">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSitio" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Adicionar Sitio</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="sitio.subgrupo"
                  label="Subgrupo"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="sitio.sitio"
                  label="Sitio"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="sitio.codigo_frac"
                  label="Código Frac"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray darken-1" outlined @click="closeSitio">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" dark @click="saveSitio">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogNomeGrupo" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Adicionar Nome Grupo</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="nome_grupo.nome_grupo"
                  label="Nome Grupo"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray darken-1" outlined @click="closeNomeGrupo">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" dark @click="saveNomeGrupo">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogGrupoQuimico" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Adicionar Grupo Quimico</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="grupo_quimico.grupo_quimico"
                  label="Grupo Quimico"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="gray darken-1" outlined @click="closeGrupoQuimico">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" dark @click="saveGrupoQuimico">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
    >
      {{ aviso }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
import { BASE_URL } from '../../util/constants';
export default {
  data: () => ({
    url: "https://apiadmin.allgeconsultoria.com/index.php/frac/",
    overlay: false,
    snackbar: false,
    aviso: '',
    items: [],
    dialogGrupo: false,
    dialogSitio: false,
    dialogNomeGrupo: false,
    dialogGrupoQuimico: false,
    dialogModoAcao: false,
    grupos: [],
    sitios: [],
    nome_grupos: [],
    grupo_quimicos: [],
    imagem: '',
    ingrediente: {
      id: "",
      id_grupo: '',
      id_sitio: '',
      id_nome_grupo: '',
      id_grupo_quimico: '',
      ingrediente_ativo: '',
      imagem: ''
    },
    grupo: {
      id: '',
      grupo: '',
      modo_acao: '',
    },
    sitio: {
      id: "",
      sitio: "",
      codigo_frac: "",
      subgrupo: "",
    },
    nome_grupo: {
      id: "",
      nome_grupo: "",
    },
    grupo_quimico: {
      id: "",
      grupo_quimico: "",
    },
    fieldRequired: [(v) => !!v || "Campo Obrigatorio"],
  }),
  created() {
    this.url = `${BASE_URL}frac/`;
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.fetchGrupos();
      //await this.fetchSitios();
      //await this.fetchNomeGrupos();
      //await this.fetchGrupoQuimicos();

      if (this.$route.params.id !== "") {
        await this.fetchIngrediente(this.$route.params.id);
      }
    },
    async getSitioAtivo() {
      await this.fetchSitios(this.ingrediente.id_grupo);
    },
    async getNomeGrupo() {
      await this.fetchNomeGrupos(this.ingrediente.id_sitio);
    },
    async getGrupoQuimico() {
      await this.fetchGrupoQuimicos(this.ingrediente.id_nome_grupo);
    },
    async fetchGrupos() {
      try {
        let response = await fetch(`${this.url}grupo`);
        response = await response.json();
        this.grupos = response.map(item => ({id: item.id, grupo: item.grupo}));
      } catch (error) {
        this.grupos = [];
      }
    },
    async fetchSitios(id_grupo) {
      try {
        let response = await fetch(`${this.url}sitio/${id_grupo}/all`);
        response = await response.json();
        this.sitios = response.map(item => ({id: item.id, sitio: item.sitio, subgrupo: item.subgrupo, codigo_frac: item.codigo_frac})).filter(sitio => sitio.sitio !== "").sort((a, b) => a.sitio > b.sitio ? 1 : -1);
      } catch (error) {
        this.sitios = [];
      }
    },
    async fetchNomeGrupos(id_sitio) {
      try {
        let response = await fetch(`${this.url}nome-grupo/${id_sitio}/all`);
        response = await response.json();
        this.nome_grupos = response.map(item => ({id: item.id, nome_grupo: item.nome_grupo})).filter(item => item.nome_grupo !== "").sort((a, b) => a.nome_grupo > b.nome_grupo ? 1 : -1);
      } catch (error) {
        this.nome_grupos = [];
      }
    },
    async fetchGrupoQuimicos(id_nome_grupo) {
      try {
        let response = await fetch(`${this.url}grupo-quimico/${id_nome_grupo}/all`);
        response = await response.json();
        this.grupo_quimicos = response.map(item => ({id: item.id, grupo_quimico: item.grupo_quimico})).filter(item => item.grupo_quimico !== "").sort((a, b) => a.grupo_quimico > b.grupo_quimico ? 1 : -1);
      } catch (error) {
        this.grupo_quimicos = [];
      }
    },
    async fetchIngrediente(id) {
      if (id == undefined) return;
      try {
        let response = await fetch(`${this.url}ingrediente/${id}`);
        response = await response.json();
        this.ingrediente = response;
        await this.getSitioAtivo();
        await this.getNomeGrupo();
        await this.getGrupoQuimico();
      } catch (error) {
        this.ingrediente = {
          id: "",
          id_grupo: '',
          id_sitio: '',
          id_nome_grupo: '',
          id_grupo_quimico: '',
          ingrediente_ativo: '',
          imagem: ''
        };
      }
    },
    async salvar() {
      this.overlay = true;
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      let urlencoded = new URLSearchParams();
      urlencoded.append("id_grupo", this.ingrediente.id_grupo);
      urlencoded.append("id_sitio", this.ingrediente.id_sitio);
      urlencoded.append("id_nome_grupo", this.ingrediente.id_nome_grupo);
      urlencoded.append("id_grupo_quimico", this.ingrediente.id_grupo_quimico);
      urlencoded.append("ingrediente_ativo", this.ingrediente.ingrediente_ativo);
      urlencoded.append("imagem_is_null", this.imagem === '' ? true : false);
      urlencoded.append("imagem", this.imagem);
      let method = (this.ingrediente.id === '' ? 'POST' : 'PUT')
      
      let requestOptions = {
        method: method,
        headers: myHeaders,
        body: urlencoded,
      };

      fetch(`${this.url}ingrediente/${this.ingrediente.id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          this.overlay = false;
          this.aviso = 'Salvo com Sucesso';
          this.snackbar = true;
          setTimeout(() => this.$router.push(`/frac/ingrediente/`), 1000)
        })
        .catch(error => {
          console.log(error)
          this.overlay = false;
          this.aviso = 'Problema ao Salvar, entre em contato com o suporte';
          this.snackbar = true;
        });
    },
    removerImagem() {
      this.ingrediente.imagem = '';
      this.imagem = '';
    },
    openGrupo() {
      this.dialogGrupo = true;
    },
    closeGrupo() {
      this.dialogGrupo = false;
    },
    saveGrupo() {
      this.dialogGrupo = false;
    },
    openSitio() {
      this.dialogSitio = true;
    },
    closeSitio() {
      this.dialogSitio = false;
    },
    saveSitio() {
      this.dialogSitio = false;
    },
    openNomeGrupo() {
      this.dialogNomeGrupo = true;
    },
    closeNomeGrupo() {
      this.dialogNomeGrupo = false;
    },
    saveNomeGrupo() {
      this.dialogNomeGrupo = false;
    },
    openGrupoQuimico() {
      this.dialogGrupoQuimico = true;
    },
    closeGrupoQuimico() {
      this.dialogGrupoQuimico = false;
    },
    saveGrupoQuimico() {
      this.dialogGrupoQuimico = false;
    },
    saveModoAcao() {
      this.dialogModoAcao = false;
    },
  },
};
</script>
