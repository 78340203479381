<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Menu
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="">
            FRAC
          </v-list-item-title>
          <v-list-item-subtitle>
            Gestão do FRAC-BR
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>


      <v-list
        dense
        nav
      >
        <v-list-item
            link
            to="/frac/grupo"
          >
            <v-list-item-content>
              <v-list-item-title>Modo de Ação</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        <v-list-item
            link
            to="/frac/sitio"
          >
            <v-list-item-content>
              <v-list-item-title>Sítio Ativo</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        <v-list-item
            link
            to="/frac/nome-grupo"
          >
            <v-list-item-content>
              <v-list-item-title>Nome do Grupo</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        <v-list-item
            link
            to="/frac/grupo-quimico"
          >
            <v-list-item-content>
              <v-list-item-title>Grupo Químico</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        <v-list-item
          link
          to="/frac/ingrediente"
        >
          <v-list-item-content>
            <v-list-item-title>Ingrediente Ativo</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      
        <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="">
            HRAC
          </v-list-item-title>
          <v-list-item-subtitle>
            Gestão do HRAC-BR
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list
        dense
        nav
      >
        <v-list-item
          link
          to="/hrac/modo-acao"
        >
          <v-list-item-content>
            <v-list-item-title>Mecanismo de Ação</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/hrac/familia"
        >
          <v-list-item-content>
            <v-list-item-title>Grupo Químico</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/hrac/ingrediente"
        >
          <v-list-item-content>
            <v-list-item-title>Ingrediente Ativo</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="">
            IRAC
          </v-list-item-title>
          <v-list-item-subtitle>
            Gestão do IRAC-BR
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list
        dense
        nav
      >
        <v-list-item
          link
          to="/irac/sitio"
        >
          <v-list-item-content>
            <v-list-item-title>Sítio Ativo</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/irac/familia"
        >
          <v-list-item-content>
            <v-list-item-title>Grupo Químico</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/irac/modo-acao"
        >
          <v-list-item-content>
            <v-list-item-title>Modo de Ação</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/irac/ingrediente"
        >
          <v-list-item-content>
            <v-list-item-title>Ingrediente Ativo</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="">
            GNEMA
          </v-list-item-title>
          <v-list-item-subtitle>
            Gestão do GNEMA-BR
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list
        dense
        nav
      >
        <v-list-item
          link
          to="/gnema/sitio"
        >
          <v-list-item-content>
            <v-list-item-title>Sítio de Ação Primário</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/gnema/classe"
        >
          <v-list-item-content>
            <v-list-item-title>Classe ou Ativo Característico</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/gnema/modo-acao"
        >
          <v-list-item-content>
            <v-list-item-title>Modo de Ação</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/gnema/ingrediente"
        >
          <v-list-item-content>
            <v-list-item-title>Ingrediente Ativo</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="">
            LINKS
          </v-list-item-title>
          <v-list-item-subtitle>
            Gestão de Links e Embbed
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list
        dense
        nav
      >
        <v-list-item
          link
          to="/links"
        >
          <v-list-item-content>
            <v-list-item-title>Links e Logos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/recomendacoes"
        >
          <v-list-item-content>
            <v-list-item-title>Recomendações</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/embbed"
        >
          <v-list-item-content>
            <v-list-item-title>Embbed</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

    </v-navigation-drawer>

    <v-app-bar app color="#00695C" dark>
      <v-app-bar-nav-icon @click="drawer = (loggedIn ? !drawer : false)"></v-app-bar-nav-icon>

      <v-toolbar-title>Manejo de Resistência Brasil</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    data: () => ({ 
      drawer: null,
      loggedIn: false, 
    }),
    created() {
      this.loggedIn = this.verificarLogin();
      if (!this.loggedIn) {
        this.drawer = false;
        this.$router.push("/login");
      } else {
        this.drawer = true;
      }
    },
     updated() {
      this.loggedIn = this.verificarLogin();
    },
    methods: {
      logout() {
        this.deslogar();
        this.drawer = false;
        this.$router.push("/login");
      },
      verificarLogin() {
        return (localStorage.getItem("user-logado") || null) !== null;
      },
      deslogar() {
        localStorage.removeItem("user-logado");
      }
    }
  }
</script>