import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from "../views/Login.vue";
import Links from "../views/Links.vue";
import Embbed from "../views/Embbed.vue";
import Recomendacoes from "../views/Recomendacoes.vue";

/** ARQUIVOS DE ROTA DO FRAC */
import FracGrupo from '../views/Frac/Grupo.vue'
import FracGrupoForm from '../views/Frac/GrupoForm.vue'
import FracSitio from '../views/Frac/Sitio.vue'
import FracSitioForm from '../views/Frac/SitioForm.vue'
import FracNomeGrupo from '../views/Frac/NomeGrupo.vue'
import FracNomeGrupoForm from '../views/Frac/NomeGrupoForm.vue'
import FracGrupoQuimico from '../views/Frac/GrupoQuimico.vue'
import FracGrupoQuimicoForm from '../views/Frac/GrupoQuimicoForm.vue'
import FracIngrediente from '../views/Frac/Ingrediente.vue'
import FracIngredienteForm from '../views/Frac/IngredienteForm.vue'

/** ARQUIVOS DE ROTA DO HRAC */
import HracModoAcao from '../views/Hrac/ModoAcao.vue'
import HracModoAcaoForm from '../views/Hrac/ModoAcaoForm.vue'
import HracFamilia from '../views/Hrac/Familia.vue'
import HracFamiliaForm from '../views/Hrac/FamiliaForm.vue'
import HracIngrediente from '../views/Hrac/Ingrediente.vue'
import HracIngredienteForm from '../views/Hrac/IngredienteForm.vue'



/** ARQUIVOS DE ROTA DO IRAC */
import IracSitio from '../views/Irac/Sitio.vue'
import IracSitioForm from '../views/Irac/SitioForm.vue'
import IracGrupo from '../views/Irac/Grupo.vue'
import IracGrupoForm from '../views/Irac/GrupoForm.vue'
import IracFamilia from '../views/Irac/Familia.vue'
import IracFamiliaForm from '../views/Irac/FamiliaForm.vue'
import IracModoAcao from '../views/Irac/ModoAcao.vue'
import IracModoAcaoForm from '../views/Irac/ModoAcaoForm.vue'
import IracIngrediente from '../views/Irac/Ingrediente.vue'
import IracIngredienteForm from '../views/Irac/IngredienteForm.vue'



/** ARQUIVOS DE ROTA DO GNEMA */
import GnemaSitio from '../views/Gnema/Sitio.vue'
import GnemaSitioForm from '../views/Gnema/SitioForm.vue'
// import GnemaGrupo from '../views/Gnema/Grupo.vue'
// import GnemaGrupoForm from '../views/Gnema/GrupoForm.vue'
import GnemaClasse from '../views/Gnema/Classe.vue'
import GnemaClasseForm from '../views/Gnema/ClasseForm.vue'
import GnemaModoAcao from '../views/Gnema/ModoAcao.vue'
import GnemaModoAcaoForm from '../views/Gnema/ModoAcaoForm.vue'
import GnemaIngrediente from '../views/Gnema/Ingrediente.vue'
import GnemaIngredienteForm from '../views/Gnema/IngredienteForm.vue'

/** ARQUIVOS DE ROTA DO LINKS E RECOMENDACOES */


Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/links",
    name: "Links",
    component: Links
  },
  {
    path: "/embbed",
    name: "Embbed",
    component: Embbed
  },
  {
    path: "/recomendacoes",
    name: "Recomendacoes",
    component: Recomendacoes
  },
  {
    path: '/frac/ingrediente',
    name: 'FracIngrediente',
    component: FracIngrediente
  },
  {
    path: '/frac/ingrediente/edit/:id',
    name: 'FracIngredienteEdit',
    component: FracIngredienteForm
  },
  {
    path: '/frac/ingrediente/form',
    name: 'FracIngredienteForm',
    component: FracIngredienteForm
  },
  {
    path: '/frac/grupo',
    name: 'FracGrupo',
    component: FracGrupo
  },
  {
    path: '/frac/grupo/edit/:id',
    name: 'FracGrupoEdit',
    component: FracGrupoForm
  },
  {
    path: '/frac/grupo/form',
    name: 'FracGrupoForm',
    component: FracGrupoForm
  },
  {
    path: '/frac/sitio',
    name: 'FracSitio',
    component: FracSitio
  },
  {
    path: '/frac/sitio/edit/:id',
    name: 'FracSitioEdit',
    component: FracSitioForm
  },
  {
    path: '/frac/sitio/form',
    name: 'FracSitioForm',
    component: FracSitioForm
  },
  {
    path: '/frac/nome-grupo',
    name: 'FracNomeGrupo',
    component: FracNomeGrupo
  },
  {
    path: '/frac/nome-grupo/edit/:id',
    name: 'FracNomeGrupoEdit',
    component: FracNomeGrupoForm
  },
  {
    path: '/frac/nome-grupo/form',
    name: 'FracNomeGrupoForm',
    component: FracNomeGrupoForm
  },
  {
    path: '/frac/grupo-quimico',
    name: 'FracGrupoQuimico',
    component: FracGrupoQuimico
  },
  {
    path: '/frac/grupo-quimico/edit/:id',
    name: 'FracGrupoQuimicoEdit',
    component: FracGrupoQuimicoForm
  },
  {
    path: '/frac/grupo-quimico/form',
    name: 'FracGrupoQuimicoForm',
    component: FracGrupoQuimicoForm
  },
  {
    path: '/hrac/modo-acao',
    name: 'HracModoAcao',
    component: HracModoAcao
  },
  {
    path: '/hrac/modo-acao/edit/:id',
    name: 'HracModoAcaoEdit',
    component: HracModoAcaoForm
  },
  {
    path: '/hrac/modo-acao/form',
    name: 'HracModoAcaoForm',
    component: HracModoAcaoForm
  },
  {
    path: '/hrac/familia',
    name: 'HracFamilia',
    component: HracFamilia
  },
  {
    path: '/hrac/familia/edit/:id',
    name: 'HracFamiliaEdit',
    component: HracFamiliaForm
  },
  {
    path: '/hrac/familia/form',
    name: 'HracFamiliaForm',
    component: HracFamiliaForm
  },
  {
    path: '/hrac/ingrediente/edit/:id',
    name: 'HracIngredienteEdit',
    component: HracIngredienteForm
  },
  {
    path: '/hrac/ingrediente/form',
    name: 'HracIngredienteForm',
    component: HracIngredienteForm
  },
  {
    path: '/hrac/ingrediente',
    name: 'HracIngrediente',
    component: HracIngrediente
  },
  // IRAC
  {
    path: '/irac/sitio',
    name: 'IracSitio',
    component: IracSitio
  },
  {
    path: '/irac/sitio/edit/:id',
    name: 'IracSitioEdit',
    component: IracSitioForm
  },
  {
    path: '/irac/sitio/form',
    name: 'IracSitioForm',
    component: IracSitioForm
  },
  {
    path: '/irac/grupo',
    name: 'IracGrupo',
    component: IracGrupo
  },
  {
    path: '/irac/grupo/edit/:id',
    name: 'IracGrupoEdit',
    component: IracGrupoForm
  },
  {
    path: '/irac/grupo/form',
    name: 'IracGrupoForm',
    component: IracGrupoForm
  },
  {
    path: '/irac/familia',
    name: 'IracFamilia',
    component: IracFamilia
  },
  {
    path: '/irac/familia/edit/:id',
    name: 'IracFamiliaEdit',
    component: IracFamiliaForm
  },
  {
    path: '/irac/familia/form',
    name: 'IracFamiliaForm',
    component: IracFamiliaForm
  },
  {
    path: '/irac/modo-acao',
    name: 'IracModoAcao',
    component: IracModoAcao
  },
  {
    path: '/irac/modo-acao/edit/:id',
    name: 'IracModoAcaoEdit',
    component: IracModoAcaoForm
  },
  {
    path: '/irac/modo-acao/form',
    name: 'IracModoAcaoForm',
    component: IracModoAcaoForm
  },
  {
    path: '/irac/ingrediente',
    name: 'IracIngrediente',
    component: IracIngrediente
  },
  {
    path: '/irac/ingrediente/edit/:id',
    name: 'IracIngredienteEdit',
    component: IracIngredienteForm
  },
  {
    path: '/irac/ingrediente/form',
    name: 'IracIngredienteForm',
    component: IracIngredienteForm
  },
  // GNEMA
  {
    path: '/gnema/sitio',
    name: 'GnemaSitio',
    component: GnemaSitio
  },
  {
    path: '/gnema/sitio/edit/:id',
    name: 'GnemaSitioEdit',
    component: GnemaSitioForm
  },
  {
    path: '/gnema/sitio/form',
    name: 'GnemaSitioForm',
    component: GnemaSitioForm
  },
  {
    path: '/gnema/classe',
    name: 'GnemaClasse',
    component: GnemaClasse
  },
  {
    path: '/gnema/classe/edit/:id',
    name: 'GnemaClasseEdit',
    component: GnemaClasseForm
  },
  {
    path: '/gnema/classe/form',
    name: 'GnemaClasseForm',
    component: GnemaClasseForm
  },
  {
    path: '/gnema/modo-acao',
    name: 'GnemaModoAcao',
    component: GnemaModoAcao
  },
  {
    path: '/gnema/modo-acao/edit/:id',
    name: 'GnemaModoAcaoEdit',
    component: GnemaModoAcaoForm
  },
  {
    path: '/gnema/modo-acao/form',
    name: 'GnemaModoAcaoForm',
    component: GnemaModoAcaoForm
  },
  {
    path: '/gnema/ingrediente',
    name: 'GnemaIngrediente',
    component: GnemaIngrediente
  },
  {
    path: '/gnema/ingrediente/edit/:id',
    name: 'GnemaIngredienteEdit',
    component: GnemaIngredienteForm
  },
  {
    path: '/gnema/ingrediente/form',
    name: 'GnemaIngredienteForm',
    component: GnemaIngredienteForm
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
