<template>
  <v-form>
    <v-container fluid>
      <v-card elevation="12" class="pa-10">
        <v-row>
          <v-col>
            <h2>Recomendações</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Selecione o Comite</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="2">
            <v-select dense :items="items" v-model="comite" @change="handleChangeComite()" label="Comite"
              outlined></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table v-if="comite" :headers="headers" :items="desserts" sort-by="calories" class="elevation-1">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>{{
                    titleTable
                  }}</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="success" class="mb-2" v-bind="attrs" v-on="on">
                        Nova Recomendação
                        {{ getNameComite() }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{
                          formTitle
                        }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12">
                              <v-text-field 
                                dense 
                                outlined 
                                v-model="editedItem.titulo" 
                                label="Titulo"
                                hide-details
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                              <v-radio-group v-model="typeFile" row>
                                <v-radio
                                  label="Link"
                                  value="link"
                                ></v-radio>
                                <v-radio
                                  label="Arquivo"
                                  value="file"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                            <v-col v-if="typeFile === 'link'" cols="12" sm="12" md="12">
                              <v-text-field 
                                dense
                                outlined
                                v-model="editedItem.link"
                                label="Link"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col v-else cols="12" sm="12" md="12">
                              <v-file-input
                                label="Arquivo PDF"
                                outlined
                                prepend-icon="mdi-file"
                                v-model="chosenFile"
                                accept=".pdf"
                                dense
                              ></v-file-input>
                            </v-col>
                            <v-col cols="12" sm="8" md="8">
                              <v-file-input
                                label="Imagem"
                                accept="image/*"
                                outlined
                                prepend-icon="mdi-camera"
                                v-model="chosenImage"
                                dense
                              ></v-file-input>
                            </v-col>
                            <v-col v-if="editedItem.imagem" cols="12" sm="4" md="4">
                              <v-img
                                :lazy-src="editedItem.imagem"
                                max-height="75"
                                max-width="125"
                                :src="editedItem.imagem"
                              ></v-img>
                            </v-col>
                            <!-- <v-col v-if="editedItem.imagem" cols="12" sm="12" md="12">
                              <v-img
                                :lazy-src="editedItem.imagem"
                                max-height="75"
                                max-width="125"
                                :src="editedItem.imagem"
                              ></v-img>
                            </v-col> -->
                          </v-row>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                          Cancelar
                        </v-btn>
                        <v-btn color="blue darken-1" dark @click="save">
                          Salvar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="subtitle-1">Tem certeza que deseja deletar
                        essa recomendação?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                        <v-btn color="red darken-1" dark @click="deleteItemConfirm">Deletar</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                <h4>Sem recomedações cadastradas</h4>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-snackbar v-model="snackbar" timeout="2000">
      {{ aviso }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
import { BASE_URL } from "../util/constants";
export default {
  data: () => ({
    url: "https://apiadmin.allgeconsultoria.com/index.php/recomendacao/",
    comite: "",
    typeFile: "link",
    items: [
      { text: "FRAC-BR", value: "frac" },
      { text: "HRAC-BR", value: "hrac" },
      { text: "IRAC-BR", value: "irac" },
      { text: "GNEMA-BR", value: "gnema" },
    ],
    width: 600,
    height: 450,
    dialog: false,
    dialogDelete: false,
    snackbar: false,
    aviso: '',
    headers: [
      {
        text: "Titulo",
        align: "start",
        value: "titulo",
      },
      { text: "Link", value: "link" },
      { text: "Imagem", value: "imagem" },
      { text: "Ações", value: "actions", align: "end", sortable: false, width: 100 },
    ],
    desserts: [],
    editedIndex: -1,
    chosenFile: null,
    chosenImage: null,
    editedItem: {
      id: "",
      titulo: "",
      imagem: "",
      link: "",
      file: "",
    },
    defaultItem: {
      id: "",
      titulo: "",
      imagem: "",
      link: "",
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nova Recomendação"
        : "Editar Recomendação";
    },
    titleTable() {
      if (this.comite !== "") {
        let comiteSelected = this.items.find(
          (i) => i.value === this.comite
        );
        return `Recomendações ${comiteSelected.text}`;
      } else {
        return "";
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.url = `${BASE_URL}recomendacao`;
    this.initialize();
  },
  methods: {
    initialize() {
      this.desserts = [];
    },

    editItem(item) {
      // console.log(item);
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // console.log(this.editItem);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.dialogDelete = false;
      this.overlay = true;
      fetch(`${this.url}/${this.editedItem.id}`, {method: 'DELETE'})
        .then(response => response.json())
        .then(result => {
          console.log(result)
          this.overlay = false;
          this.aviso = 'Deletado com Sucesso';
          this.snackbar = true;
          this.desserts = [];
          this.editedItem = {}
          this.getRecomendacoes(this.comite);
        })
        .catch(error => {
          console.log(error)
          this.overlay = false;
          this.editedItem = {}
          this.aviso = 'Problema ao Deletar, entre em contato com o suporte';
          this.snackbar = true;
        });
      // this.desserts.splice(this.editedIndex, 1);
      // this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      let formData = new FormData();
      Object.keys(this.editedItem).map((key) => {
        formData.append(key, this.editedItem[key]);
      });
      formData.append('file-pdf', this.chosenFile);
      formData.append('imagem-upload', this.chosenImage);

      if (this.editedIndex > -1) {
        this.put(this.editedItem.id, formData);
      } else {
        this.post(formData);
      }
      this.chosenFile = null;
      this.chosenImage = null;
      this.typeFile = 'link';
      this.close();
    },
    async put(id, data) {
      try {
        let response = await fetch(`${this.url}/${id}`, {
          method: "POST",
          body: data,
        });
        console.log(response);
        this.aviso = 'Salvo com sucesso';
        this.snackbar = true;
        this.dialog = false;
        this.getRecomendacoes(this.comite);
      } catch (error) {
        this.aviso = 'Erro ao salvar, entre em contato com o suporte';
        this.snackbar = true;
      }
    },
    async post(data) {
      // let myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");
      data.append('comite', this.comite);
      try {
        let response = await fetch(`${this.url}`, {
          method: "POST",
          body: data,
        });
        console.log(response);
        this.aviso = 'Salvo com sucesso';
        this.snackbar = true;
        this.dialog = false;
        this.getRecomendacoes(this.comite);
      } catch (error) {
        this.aviso = 'Erro ao salvar, entre em contato com o suporte';
        this.snackbar = true;
      }
    },
    handleChangeComite() {
      console.log(this.comite);
      this.getRecomendacoes(this.comite);
    },
    async getRecomendacoes(comite) {
      this.desserts = [];
      try {
        let response = await fetch(`${this.url}/${comite}`);
        this.desserts = await response.json();
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
    getNameComite() {
      if (this.comite !== "") {
        let comiteSelected = this.items.find(
          (i) => i.value === this.comite
        );
        return comiteSelected.text;
      }
      return "";
    },
  },
};
</script>
