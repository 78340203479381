<template>
  <v-form>
    <v-container fluid>
      <v-card elevation="12" class="pa-10">
        <v-row>
          <v-col>
            <h2>Embbed</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Selecione o Comite</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="2">
            <v-select
              :items="items"
              v-model="comite"
              label="Comite"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Largura do Embbed (width)</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="1">
            <v-text-field
              v-model="width"
              label="Largura (width)"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Altura do Embbed (height)</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="1">
            <v-text-field
              v-model="height"
              label="Altura (height)"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            <v-subheader>Link do Embbed</v-subheader>
          </v-col>
          <v-col class="d-flex" cols="5">
            <v-text-field
              :value="linkEmbbed()"
              label="Link"
              dense
              readonly
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    url: "",
    comite: '',
    items: [
      {text: 'FRAC-BR', value: 'frac'},
      {text: 'HRAC-BR', value: 'hrac'},
      {text: 'IRAC-BR', value: 'irac'},
      {text: 'GNEMA-BR', value: 'gnema'},
    ],
    width: 600,
    height: 450,
  }),
  methods: {
    linkEmbbed() {
      let url = `https://embedded.allgeconsultoria.com/${this.comite}.html`;
      if (this.comite === '') {
        return '';
      }
      return `<iframe width="${this.width}" height="${this.height}" src="${url}" frameborder="0"></iframe>`;
    },
  },
};
</script>
